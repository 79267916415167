@import "custom-select";

@import "plugins";
@import "variables";

@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/type";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/nav";

@import "mixins";
@import "utilities";
@import "animations";
@import "typography";
@import "ads";
@import "components";
@import "header";
@import "footer";
@import "hero";
@import "subnav";
@import "forms";
@import "sidebar";


body {
    height: 100%;
    min-height: 480px;
    background-color: $black;
}

main {
    position: relative;
    z-index: 1;
    min-height: 50vh;
    padding-bottom: 1px;
}

.container {
    // No padding on nested containers
    .container {
        padding-left: 0;
        padding-right: 0;
    }

    @include xs {
        padding-left: 50px;
        padding-right: 50px;
    }

    @include sm {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.page {
    transform-origin: 50% 0%;
    overflow: hidden;
    background-color: $white;

    @include md {
        min-height: 150vh;
    }
}

.overlay {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    width: 100%;
    background-color: $black;
    height: 100%;
    overflow: hidden;
    perspective: 200px;

    img {
        position: absolute;
        z-index: 3;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        width: 100px;
        height: 100px;
        opacity: 0;
    }

    @include md {
        img {
            left: calc(50% - 80px);
            top: calc(50% - 80px);
            width: 160px;
            height: 160px;
        }
    }
}

img {
    max-width: 100%;
}

.img-wrap {
    position: relative;
    overflow: hidden;
    transition: opacity 0.5s, transform 0.5s $snappy;

    img {
        @include fill;
        display: block;
        opacity: 0;
        transform: scale(1.1);
        transition: opacity 0.5s, transform 0.5s $snappy;

        &.lazyloaded {
            opacity: 1;
            transform: scale(1);
        }
    }
}

a.excerpt {
    position: relative;
    display: block;
    text-decoration: none;

    .img-wrap {
        background: $lightest-tan;
    }

    &:hover .img-wrap {
        transform: translateY(-10px);
    }
}

figure a {
    img {
        transition: transform 0.5s $snappy;
    }

    &:hover img {
        transform: translateY(-10px);
    }
}

svg.icon {
    width: 0.9em;
    height: 0.9em;
    margin: 0 0.5rem;
    fill: currentColor;
    vertical-align: baseline;
}


input[type="text"],
input[type="button"],
input[type="submit"],
input[type="search"],
input[type="email"],
textarea {
    -webkit-appearance: none;
    border-radius: 0;
}

:focus {
    outline: 2px solid $blue;
}


.hs-fieldtype-select {
    label {
        display: block;
        width: 100%;
    }
}

.custom-select, .hs-fieldtype-select .input {
    position: relative;
    display: inline-block;
    select {
        background-color: #fff;
        appearance: none;
        border-radius: 0px;
        padding: 10px 30px 10px 10px;
        border: 1px solid $gray-400;
        color: #58595b;
    }    
    &:after {
        content: "\25BC";
        font-size: 0.5em;
        color: $black;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}

.Typewriter__cursor {
    font-family: $font-family-sans;
    font-weight: $font-weight-light;
}

.bg-light-grey {
    background-color: $lightest-blue !important;
}
.bg-light-tan {
    background-color: $lightest-tan !important;
}

.headshot {
    display: block;
    border-radius: 50%;
    overflow: hidden;
}

.share {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .eyebrow-bold {
        margin: 2px 0.5rem 0 0;
    }

    a {
        display: block;
        color: $red;
    }

    .icon {
        display: block;
        width: 1rem;
        height: 1rem;
    }

    @include md {
        justify-content: flex-end;
        margin: 0;
    }
}
