body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-weight: $font-weight-light;
    font-size: rem(36px);
    line-height: rem(40px);
    letter-spacing: 0.25px;

    @include lg {
        font-size: rem(54px);
        line-height: rem(60px);

        &.xl {
            font-size: rem(70px);
            line-height: rem(76px);
        }
    }
}

h2 {
    font-weight: $font-weight-light;
    font-size: rem(30px);
    line-height: rem(34px);
    letter-spacing: 0.25px;

    @include lg {
        font-size: rem(40px);
        line-height: rem(44px);
    }
}

h3 {
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-bold;
    font-size: rem(24px);
    line-height: rem(30px);
}

h4 {
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-bold;
    font-size: rem(20px);
    line-height: rem(24px);
}

h5, h6 {
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-bold;
    font-size: rem(18px);
    line-height: rem(22px);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: currentColor;

    em,
    strong,
    &.serif {
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: normal;
    }

    a,
    a:hover {
        text-decoration: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    p + &,
    ol + &,
    ul + & {
        margin-top: 1rem;
    }
}

h1, h2 {
    a {
        position: relative;
        padding: 0 3px;

        background-image: linear-gradient(to right, $lightest-blue, $lightest-blue);
        background-size: 1px 10px;
        background-position: 0 calc(100% - 0.25em);
        background-repeat: repeat-x;
        transition: background-size 0.25s;

        &:hover {
            // background-image: linear-gradient(to right, grey, $darker-grey);
            background-size: 1px 2px;
        }
    }
}

h3, h4, h5, h6 {
    a {
        background-image: linear-gradient(to right, transparent, transparent);
        background-size: 1px 1px;
        background-position: 0 1.1em;
        background-repeat: repeat-x;

        &:hover {
            background-image: linear-gradient(to right, currentColor, currentColor);
        }
    }
}

p:last-child {
    margin-bottom: 0;
}

a,
button {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &.active {
        color: inherit;
    }
}

.underline {
    background: 
    linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, rgba(255, 185, 102, 1), rgba(255, 185, 102, 1));
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;

    a:hover &,
    header a.active & {
        background-size: 0 0.1em, 100% 0.1em;
    }
}

blockquote,
.blockquote {
    margin: 15px 0;
    padding-left: 1.5rem;
    border-left: 5px solid $lightest-tan;
    font-size: inherit;

    &:last-child {
        margin-bottom: 0;
    }
}

.pullquote {
    position: relative;
    width: 275px;
    margin: $spacer*1.5 auto;
    padding: $spacer 0;
    font-family: $font-family-serif;
    font-size: rem(24px);
    line-height: rem(30px);
    letter-spacing: 0.5px;
    text-align: center;
    color: $red;

    &::before,
    &::after {
        content: " ";
        position: absolute;
        left: calc(50% - 15px);
        width: 30px;
        height: 6px;
        background-color: $lightest-blue;
    }

    &::before { top: 0; }
    &::after { bottom: 0; }

    @include md {
        float: right;
        width: 220px;
        margin-top: $spacer;
        margin-right: -240px;
    }

    @include lg {
        margin-right: -400px;
        font-size: rem(26px);
        line-height: rem(32px);
    }

    @include xl {
        margin-right: -475px;
    }
}

ul, ol, dl {
    padding-left: 2rem;
}

li {
    margin-bottom: 0.5rem;
}

.btn {
    min-width: 225px;
    margin-right: 1rem;
    margin-top: 1rem;
    text-transform: uppercase;
    text-decoration: none;

    @include md {
        padding: .5rem 1.5rem;
    }
}

.btn-dark:hover {
    background-color: $blue;
    border-color: $blue;
    color: $black;
}

.btn-light:hover {
    background-color: $yellow;
    border-color: $yellow;
}

.btn-outline-light {
    background-color: transparentize($black, 0.75);

    &::before,
    &::after,
    span::before,
    span::after {
        background-color: $yellow;
    }
}

.btn-outline-light:hover {
    color: $yellow;
    border-color: $white;
    background-color: transparentize($black, 0.75);
}

.btn-outline-dark {
    &::before,
    &::after,
    span::before,
    span::after {
        background-color: $blue;
    }
}

.btn-outline-dark:hover {
    color: $dark;
    background-color: transparent;
}

.btn.animate-outline {
    position: relative;
    padding: 0;

    span {
        display: block;
        padding: $btn-padding-y $btn-padding-x;
    }

    &::before,
    &::after,
    span::before,
    span::after {
        content: " ";
        position: absolute;
    }

    &::before {
        left: -2px;
        top: -2px;
        width: calc(100% + 4px);
        height: 2px;
        transform-origin: 0 0;
        transform: scaleX(0);
        transition: transform 0.5s $ease-out 0.5s;
    }

    &::after {
        left: -2px;
        bottom: -2px;
        width: calc(100% + 4px);
        height: 2px;
        transform-origin: 100% 0;
        transform: scaleX(0);
        transition: transform 0.5s $ease-out 0.5s;
    }

    span::before {
        left: -2px;
        top: -2px;
        width: 2px;
        height: calc(100% + 4px);
        transform-origin: 0 100%;
        transform: scaleY(0);
        transition: transform 0.5s $ease-in 0s;
    }

    span::after {
        right: -2px;
        top: -2px;
        width: 2px;
        height: calc(100% + 4px);
        transform-origin: 0 0;
        transform: scaleY(0);
        transition: transform 0.5s $ease-in 0s;
    }

    &:hover {
        &::before,
        &::after {
            transform: none;
            transition: transform 0.5s $ease-in 0s;
        }

        span::before,
        span::after {
            transform: none;
            transition: transform 0.5s $ease-out 0.5s;
        }
    }

    @include md {
        span {
            padding: .5rem 1.5rem;
        }
    }
}

.caption {
    clear: both;
    font-weight: $font-weight-bold;
    font-size: rem(12px);
    line-height: rem(17px);
}

@mixin nav-link {
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-bold;
    font-size: rem(14px);
    line-height: rem(20px);
    text-transform: uppercase;
    text-decoration: none;
}
.nav-link { @include nav-link; }

@mixin eyebrow {
    display: inline-block;
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-light;
    font-size: rem(16px);
    line-height: rem(20px);
    text-transform: uppercase;

    a {
        text-decoration: none;
    }

    p + &,
    ol + &,
    ul + & {
        margin-top: 1.25rem;
    }
}
.eyebrow { @include eyebrow; }

@mixin eyebrow-bold {
    @include eyebrow;
    font-weight: $font-weight-bold;
}
.eyebrow-bold {
    @include eyebrow-bold;

    // Automatically underline links within,
    // unless underline-class has been specified already
    &[class="eyebrow-bold"] a {
        @extend .underline-grey;
    }
}

// Adjust margins between eyebrows and headings
h1, h2, h3, h4, h5, h6 {
    & + .eyebrow,
    & + .eyebrow-bold {
        display: block;
        margin-top: -0.75rem;
    }

    .eyebrow + &,
    .eyebrow-bold + & {
        margin-top: 0.75rem;
    }
}

.author-name {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-family: $font-family-serif;
    font-size: rem(20px);
    line-height: rem(26px);
}

@mixin cta {
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-bold;
    font-size: rem(24px);
    line-height: rem(30px);
    letter-spacing: -0.25px;

    @include lg {
        font-size: rem(35px);
        line-height: rem(42px);
    }
}
.cta { @include cta; }

@mixin deck {
    margin-bottom: 1.5rem;
    font-family: $font-family-sans-alt;
    font-size: rem(20px);
    line-height: rem(25px);

    @include lg {
        font-size: rem(22px);
        line-height: rem(28px);
    }
}
.deck { @include deck; }

.underline-white,
.underline-grey,
.underline-black {
    position: relative;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &::after {
        content: " ";
        position: absolute;
        left: -3px;
        bottom: 0;
        width: calc(100% + 6px);
        height: 10px;
        mix-blend-mode: overlay;
        pointer-events: none;
        transition: all 0.25s $snappy;
    }

    &:hover::before {
        left: 0;
        bottom: 8px;
        width: 100%;
        height: 2px;
        background-color: $darker-grey;
    }
}

.underline-white::after { background-color: $white; }
.underline-grey::after { background-color: $lightest-blue; }
.underline-black::after { background-color: $black; }

.vertical-rule {
    position: relative;
    margin: 20px 0;
    padding-left: 1.5rem;

    & + .vertical-rule {
        padding-top: 20px;
        margin-top: -20px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.isolated-link {
    font-family: $font-family-sans-alt;
    font-weight: $font-weight-bold;
    font-size: rem(17px);
    line-height: rem(24px);
}
