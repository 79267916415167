a.skip {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px 0;
    @include nav-link;
    background-color: $black;
    color: $white;
    opacity: 0;

    &:focus {
        z-index: 11;
        opacity: 1;
    }
}

header {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    padding: 15px 0;
    transition: background-color 0.35s, box-shadow 0.5s, transform 0.5s $snappy;

    .container {
        position: relative;
        display: flex;
        align-items: center;
    }

    .logo {
        position: relative;
        z-index: 1;
        display: block;
        width: 174px;
        color: $black;
        transition: color 0.25s;

        svg {
            display: block;
            width: 174px;
            height: 50px;
        }
    }

    .menu-toggle {
        position: relative;
        z-index: 1;
        display: block;
        margin-left: auto;
        padding: 10px 0 10px 60px;
        @include nav-link;
        color: $black;
        transition: color 0.25s;

        span {
            position: absolute;
            right: 0;
            top: 50%;
            display: block;
            transition: transform 0.5s $snappy;

            &.open { transform: translate(0, -50%) scaleY(1); transform-origin: 50% 0; }
            &.close { transform: translate(0, -50%) scaleY(0); transform-origin: 50% 100%; }
        }

        svg {
            width: 18px;
            height: 18px;
            transition: transform 0.5s $snappy;
            transform: scale(0) rotate(-90deg);
        }
    }

    ul#menu {
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        height: calc(100% - 60px);
        min-height: max-content;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: $black;
        opacity: 0;
        transition: opacity 0.25s, visibility 0s linear 0.25s;
        visibility: hidden;

        li {
            display: block;
            margin-bottom: 0;
            transition: transform 0.25s $snappy;

            &:nth-child(1) { transform: translateY(10px); }
            &:nth-child(2) { transform: translateY(20px); }
            &:nth-child(3) { transform: translateY(30px); }
            &:nth-child(4) { transform: translateY(40px); }
            &:nth-child(5) { transform: translateY(50px); }
            &:nth-child(6) { transform: translateY(60px); }
        }

        a {
            display: block;
            padding: 10px;
            text-align: center;
            font-family: $font-family-serif;
            font-size: rem(30px);
            line-height: rem(34px);
            color: $white;
            transition: color 0.25s;
            text-decoration: none;
        }
    }

    form {
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 15px;
        background: $red;
        opacity: 0;
        transition: opacity 0.25s, visibility 0s linear 0.25s;
        visibility: hidden;

        button {
            order: 1;
            display: block;
            width: 40px;
            height: 40px;
            border: none;
            background: transparent;
            color: $white;
            transition: color 0.25s;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        label {
            order: 2;
            flex-shrink: 1;
            width: 100%;
            margin-bottom: 0;
        }

        input {
            width: 100%;
            border: none;
            border-bottom: 2px solid $white;
            background: transparent;
            @include nav-link;
            color: $white;
        }

        input::placeholder {
            color: $white;
        }
    }

    @include md {
        overflow: hidden;

        .menu-toggle {
            display: none;
        }

        ul#menu {
            position: relative;
            left: 0;
            top: 0;
            flex-flow: row nowrap;
            align-items: center;
            width: auto;
            margin-left: auto;
            padding: 0;
            background-color: transparent;
            visibility: visible;
            opacity: 1;
            transition: opacity 0.25s, transform 0.5s $snappy;
            transform: translateX(0);

            li {
                transform: translateY(0) !important;
            }

            a {
                @include nav-link;
                color: $black;
            }
        }

        form {
            position: relative;
            display: block;
            width: 30px;
            height: 50px;
            padding: 5px 10px;
            opacity: 1;
            visibility: visible;
            background: transparent;

            .container {
                display: flex;
                align-items: center;
                width: 300px;
                transition: transform 0.5s $snappy;
            }

            button {
                color: $black;
            }

            input {
                border-bottom-color: $black;
                color: $black;
                opacity: 0;
                transition: opacity 0.25s;

                &:focus {
                    border-bottom-color: $black;
                }
            }

            input::placeholder {
                color: $black;
            }
        }
    }

    @include lg {
        ul#menu a {
            padding: 10px 20px;
        }
    }
}

header.stuck {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: $white;
    transform: translateY(-100%);

    &.active {
        box-shadow: 1px 1px 4px rgba(0,0,0,0.35);
        transform: none;
    }

    @include md {
        color: $black;

        .logo,
        .menu-toggle,
        ul#menu a,
        form button {
            color: $black;
        }

        form input {
            border-bottom-color: $black;
            color: $black;
        }

        form input::placeholder {
            color: $black;
        }
    }
}

html.overflow-mobile-stuck {
    overflow: hidden;  
    @include md {
        overflow: unset;
    }
}

html.menu-active {
    overflow: hidden;
    @include md {
        overflow: unset;
    }
    header {
        .logo {
            color: $white;
        }

        .menu-toggle {
            color: $white;

            span {
                &.open { transform: translate(-30px, -50%) scaleY(0); }
                &.close { transform: translate(-30px, -50%) scaleY(1); }
            }

            svg {
                transform: scale(1) rotate(0);
            }
        }

        ul#menu {
            opacity: 1;
            transition: opacity 0.25s, visibility 0s linear 0s;
            visibility: visible;

            li {
                transform: translateY(0) !important;
            }
        }

        form {
            opacity: 1;
            transition: opacity 0.25s, visibility 0s linear 0s;
            visibility: visible;
        }
    }
}

html.search-active {
    @include md {
        header {
            ul#menu {
                opacity: 0;
                transform: translateX(-100px);
            }

            form {
                .container {
                    transform: translateX(-300px);
                }

                input {
                    opacity: 1;
                }
            }
        }
    }
}

body.header-light header:not(.stuck) {
    color: $white;

    .logo,
    .menu-toggle,
    ul#menu a,
    form button {
        color: $white;
    }

    form button:hover {
        color: $yellow;
    }

    ul#menu a:hover {
        color: $yellow;
    }

    form input {
        border-bottom-color: $white;
        color: $white;
    }

    form input::placeholder {
        color: $white;
    }
}
