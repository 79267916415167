section.hero {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 100px 0;
    background-color: $black;
    color: $white;

    .container {
        position: relative;
        z-index: 2;
        margin-top: 30px;
    }

    video {
        @include fill;
        z-index: 1;
        display: block;
        object-fit: cover;
    }

    .img-wrap {
        @include fill;
        z-index: 1;
        padding-top: 0 !important;

        img {
            @include fill;
            display: block;
            object-fit: cover;
        }
    }

    .btn {
        display: block;
    }

    @include sm {
        .btn {
            display: inline-block;
        }
    }

    @include lg {
        .container {
            margin-top: 60px;
        }
    }
}

section.hero-small {
    min-height: 50vh;

    @include md {
        min-height: 500px;
    }
}

section.hero-large {
    min-height: 80vh;

    @include md {
        min-height: 600px;
    }

    @include lg {
        min-height: 740px;
    }
}

section.hero-careers {
    justify-content: flex-end;
    padding-bottom: 60px;
    background-color: $lightest-tan;
    color: $black;

    @include md {
        min-height: 340px;
    }
}

section.hero-work {
    justify-content: flex-end;
    padding-bottom: 0;
    background-color: $white;
    color: $black;

    @include md {
        min-height: 340px;
    }
}

// Add padding for pages without a hero section
main > .page > section:not(.hero):first-child {
    margin-top: 0;
    padding-top: 100px;

    @include lg {
        padding-top: 120px;
    }

    @include xl {
        padding-top: 180px;
    }
}
