.sidebar-form {
	display: none;
	// transition: opacity 1s, transform 0.5s cubic-bezier(0.38, 0.99, 1, 1), margin 250ms;
	padding: 20px 0 20px 0;

	@include md {
		display: block;
	}

	&.stuck {
		position: fixed;
		top: 0;
		&.offset-top {
			margin-top: 100px;
		}	
	}

	&.absolute {
		position: absolute;
		//share offset
		bottom: 22px;
	}

	h2 {
		font-size: 24px;
		font-weight: 900;
		line-height: 120%;
		letter-spacing: normal;
	}

	.hs-form-field {
		margin-bottom: 15px;
	}
	
	.hbspt-form input[type="text"], .hbspt-form input[type="email"] {
		padding: 0 10px;
	}
	
	.inputs-list {
		margin: 5px 0 0 0!important;
		padding: 0!important;
	}
	
	.hbspt-form input[type=submit] {
		background-color: #000;
		border-color: #000;
		color: #FFF;
		&:hover {
			color: #000;
			background-color: #E1B966;
			border-color: #E1B966;
		}
	}

}