.custom-select-container {
    position: relative;
    box-sizing: border-box;
  }
  .custom-select-container * {
    box-sizing: border-box;
  }
  .custom-select-container.is-disabled {
    opacity: .333;
  }
  .custom-select-opener {
    background-color: none;
    border: 1px solid #FFF;
    padding: 0.5em;
    display: block;
    cursor: pointer;
    width: 100%;
    &:after {
        width: 0; 
        height: 0; 
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid #FFF;      
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 20px;
    }
  }
  .custom-select-container select {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
  .custom-select-panel {
    max-height: 0;
    transition: max-height .5s ease-out, overflow-y 0.1s 0.5s;
    overflow: hidden;
    background-color: #000;
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
  }
  .custom-select-container.is-open .custom-select-panel {
    max-height: 7.7em;
    overflow-y: auto
  }
  .custom-select-option {
    padding: 0.5em;
  }
  .custom-select-option.has-focus {
    background-color: #E1B966;
  }
  .custom-select-option.is-selected::before {
    content: "✔";
    padding-right: 0.5em;
  }
  .custom-select-optgroup > .custom-select-option {
    padding-left: 2em;
  }
  .custom-select-optgroup::before {
    content: attr(data-label);
    display: block;
    padding: 0.5em;
    color: #888;
  }
  