
.filterbar-mobile-menu {
  position: fixed;
  display: none;
  z-index: 999;
  align-items: center;
  justify-content: center;
  color: #FFF;
  background-color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  overflow-y: scroll;
  &__close {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    svg.icon {
      width: 100%;
      height: 100%;
      margin: 0;
      display: block;
    }
  }
  &__content {
    width: 100%;
    padding: 20px;
    max-width: 320px;
    margin: auto;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      a, button {
        text-decoration: none;
        padding: 0;
      }
      button {
        font-weight: bold;
      }
      svg {
        transition: all 250ms;
      }
      ul {
        height: 0;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-20px);
      }
      &.active {
        svg {
          transform: scaleY(-1);
        }
        ul {
          margin: 10px 0 20px 0;
          padding-left: 20px;
          height: auto;
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          transition: opacity 0.25s, transform 0.5s cubic-bezier(0.42, 0, 0.05, 0.99), visibility 0s linear 0s;
        }
      }
    }
  }
  button {
    appearance: none;
    color: inherit;
    background: none;
    border: none;
  }
  input[type="text"] {
    width: 100%;
    @include nav-link;
    padding: 0;
    margin-left: 6px;
    border: none;
    border-bottom: 2px solid #FFF;
    background: none;
    color: #FFF;
    &::placeholder {
      color: #fff;
    }
  }
  form.search {
    width: 100%;
    max-width: 280px;
    margin: 50px 0 0 0;
    display: flex;
    align-items: center;
    button[type="submit"] {
      width: 40px;
      height: 20px;
      padding: 0;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

nav.filterbar {
  position: relative;
  z-index: 1;
  @include clearfix;
  color: $darker-grey;
  .mobile-menu-toggle-button {
    display: block;
    font-family: benton-sans-condensed, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    background-color: $lightest-blue;
    background-image: url("../icons/expand.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    appearance: none;
    border: none;
    width: 100%;
    padding-right: 59px;
    height: 59px;
    @include off-grid-width;
    @include md {
      display: none;
    }
  }
  .container {
    ul {
      margin: 0;
      li {
        list-style: none;
      }
      &.active {
        button.toggle {
          background-color: $red;

          svg {
            transform: scaleY(-1);
          }
        }
      }
    }
    > ul {
      width: 100%;
      padding: 0;
      align-items: stretch;
      background-color: $lightest-blue;
      @include off-grid-width;
      display: none;
      @include md {
        display: flex;
      }    
      > li {
        &:not(.search-wrap):hover, &.active {
          background-color: $blue;
          color: #FFF;
        }
      }
      li {
        display: flex;
        position: relative;
        align-items: center;
        margin: 0 0 0 0;
        cursor: pointer;
        &.search-wrap {
          margin-left: auto;
          margin-right: 30px;
        }
        &.dropdown-link {
          button {
            display: flex;
            align-items: center;
            appearance: none;
            border: none;
            background: transparent;
            margin: 0 0 0 8px;
            font-family: benton-sans-condensed, sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            padding: 20px 30px;
            svg {
              transition: transform 0.35s cubic-bezier(0.42, 0, 0.05, 0.99);
            }
          }
        }
        ul.dropdown {
          position: absolute;
          left: 0;
          top: 100%;
          display: block;
          width: 200px;
          height: 0;
          padding: 20px 0;
          background-color: $dark-blue;
          visibility: hidden;
          opacity: 0;
          transform: translateY(-20px);
          transition: opacity 0.25s, transform 0.5s $snappy,
          visibility 0s linear 0.5s;
            

          li {
            display: block !important;
          }

          a {
            display: block;
            @include nav-link;
            color: #FFF;
            transition: color 0.25s;
            background: transparent;
            border: none;
            padding: 10px 30px;
          }
        }

        &.active {
          svg {
            transform: scaleY(-1);
          }

          ul {
            height: auto;
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.25s, transform 0.5s $snappy,
              visibility 0s linear 0s;
          }
        }
      }
    }
    
    form {
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 265px;
      margin: $spacer 0;

      button {
        order: 1;
        display: block;
        width: 40px;
        height: auto;
        border: none;
        background: transparent;
        color: $black;

        svg {
          width: 20px;
          height: 20px;
          path {
            fill: #fff;
          }
        }
      }
        
      input {
        order: 2;
        width: 100%;
        flex-shrink: 1;
        border: none;
        border-bottom: 2px solid $darker-grey;
        background: transparent;
        @include nav-link;
      }

      input::placeholder {
        color: $darker-grey;
      }
    }
  }
}

nav.subnav {
  position: relative;
  z-index: 1;
  @include clearfix;

  ul {
    @include off-grid-width;
    height: 60px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.35);

    &.active {
      > li {
        border-bottom: 2px solid $dark-grey;
        transform: translateY(0) !important;
        transition: transform 0.35s $snappy;
      }

      button.toggle {
        background-color: $red;

        svg {
          transform: scaleY(-1);
        }
      }
    }
  }

  li {
    position: relative;
    margin-bottom: 0;
    transition: transform 0.35s $snappy;
  }

  li:nth-child(1) {
    z-index: 1;
  }
  li:nth-child(2) {
    transform: translateY(-100%);
  }
  li:nth-child(3) {
    transform: translateY(-200%);
  }
  li:nth-child(4) {
    transform: translateY(-300%);
  }
  li:nth-child(5) {
    transform: translateY(-400%);
  }
  li:nth-child(6) {
    transform: translateY(-500%);
  }
  li:nth-child(7) {
    transform: translateY(-600%);
  }
  li:nth-child(8) {
    transform: translateY(-700%);
  }
  li:nth-child(9) {
    transform: translateY(-800%);
  }
  li:nth-child(10) {
    transform: translateY(-900%);
  }

  ul {
    background-color: $lightest-blue;
    li {
      background-color: $lightest-blue;
    }
    a, span {
      color: $darker-grey!important;
    }
    button {
      color: #FFF;
    }
  }

  &.category-filters {
    ul {
      background-color: $darker-grey;
      li {
        background-color: $darker-grey;
      }
      a, button, span {
        color: $white!important;
      }
    }
  }

  button.toggle {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 0;
    font-size: 2.5rem;
    background-color: $yellow;
    color: $white;
    border: none;
    transition: background-color 0.25s;

    svg {
      transition: transform 0.35s $snappy;
    }
  }

  a,
  span:not(.underline),
  li.overflow button {
    display: block;
    padding: 20px 30px;
    @include nav-link;
    color: $darker-grey;
    transition: color 0.25s;
    background: transparent;
    border: none;
  }

  a.active {
    
    span.underline {
      background-image: linear-gradient(to right, transparent, transparent), linear-gradient(to right, rgb(255, 185, 102), rgb(255, 185, 102));
      background-size: 0 0.1em, 100% 0.1em;
    }
  }

  form {
    display: flex;
    align-items: center;
    width: 100%;
    margin: $spacer 0;

    button {
      order: 1;
      display: block;
      width: 40px;
      height: 40px;
      border: none;
      background: transparent;
      color: $black;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    input {
      order: 2;
      width: 100%;
      flex-shrink: 1;
      border: none;
      border-bottom: 2px solid $black;
      background: transparent;
      @include nav-link;
      color: $black;
    }

    input::placeholder {
      color: $black;
    }
  }

  @include lg {
    ul {
      display: flex;
      align-items: center;
      background-color: $lightest-blue;

      &.active > li {
        border-bottom: none;
      }

      li {
        flex-shrink: 0;
      }
    }

    li:nth-child(1) {
      display: none;
    }
    li:nth-child(n + 2) {
      transform: translateY(0);
    }

    ul ul {
      position: absolute;
      left: 0;
      top: 100%;
      display: block;
      width: 200px;
      height: 0;
      padding: 20px 0;
      background-color: $lightest-tan;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
      visibility: hidden;
      opacity: 0;
      transform: translateY(-20px);
      transition: opacity 0.25s, transform 0.5s $snappy,
        visibility 0s linear 0.5s;

      li {
        display: block !important;
        background-color: $lightest-tan;
      }

      a {
        padding: 10px 30px;
        color: $black;
      }
    }

    li.overflow {
      cursor: pointer;

      svg {
        width: 20px;
        transition: transform 0.35s $snappy;
      }

      &.active {
        svg {
          transform: scaleY(-1);
        }

        ul {
          height: auto;
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          transition: opacity 0.25s, transform 0.5s $snappy,
            visibility 0s linear 0s;
        }
      }
    }

    li.search-wrap {
      width: 260px;
      margin-left: auto;
      margin-right: $spacer;
    }

    ul form {
      button,
      input,
      input::placeholder {
        color: $white;
      }

      input {
        border-bottom-color: $white;
      }
    }
  }
}

nav.subnav-small {
  @include lg {
    ul {
      @include pull-to-edge;
      width: 520px;
    }
  }
}
