body.prevent-transitions * {
    transition: none !important;
}

.fade-in-up {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s, transform 0.5s $ease-out;

    &.active {
        opacity: 1;
        transform: translateY(0);
    }
}

.slide-in-left {
    transform: translateX(99%);
    transition: transform 1s $snappy;

    &.active {
        transform: translateX(0);
    }
}

.slide-in-right {
    transform: translateX(-99%);
    transition: transform 1s $snappy;

    &.active {
        transform: translateX(0);
    }
}

.content-wrap .fade-in-up {
    transition-delay: 0.5s;
}

.vertical-rule {
    &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 3px;
        width: 6px;
        height: calc(100% - 7px);
        background-color: $lightest-blue;
        transform-origin: 0 0;
        transform: scaleY(0);
        transition: transform 1s $snappy;
        transition-delay: 0.25s;
    }

    &.active::before {
        transform: scaleY(1);
    }
}
