footer {
    position: relative;
    z-index: 1;
    margin-top: -1px;
    padding: 10px 0 40px;
    background-color: $black;

    a.logo {
        display: block;
        margin-bottom: 15px;
        padding: 25px 15px;
        text-align: center;
        border-bottom: 1px solid $dark-blue;
        @include nav-link;
        color: $white;

        &:hover {
            color: $yellow;
        }
    }

    ul.menu {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin-bottom: 0;
        }

        a {
            display: block;
            padding: 15px;
            text-align: center;
            @include nav-link;
            font-weight: $font-weight-regular;
            color: $white;

            &:hover {
                color: $yellow;
            }
        }
    }

    ul.social {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin-bottom: 0;
        }

        a {
            display: block;
            padding: 10px;
            font-size: rem(20px);
            color: $white;

            &:hover {
                color: $blue;
            }
        }
    }

    @include md {
        padding: 0;

        .container {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
        }

        a.logo {
            margin-bottom: 0;
            padding: 10px 0;
            border-bottom: none;
        }

        ul.menu {
            display: flex;
            align-items: center;
            margin-left: auto;
        }

        ul.social {
            width: 100%;

            a {
                padding: 10px 0;
            }
        }
    }

    @include lg {
        ul.social {
            width: auto;
            margin-left: 10px;
        }
    }
}
