// Responsive Margins

.mt-large {
    margin-top: 60px;

    @include sm { margin-top: 100px; }
    @include md { margin-top: 160px; }
    @include lg { margin-top: 200px; }
    @include xl { margin-top: 240px; }
    @include xxl { margin-top: 280px; }
}

.mb-large {
    margin-bottom: 60px;

    @include sm { margin-bottom: 100px; }
    @include md { margin-bottom: 160px; }
    @include lg { margin-bottom: 200px; }
    @include xl { margin-bottom: 240px; }
    @include xxl { margin-bottom: 280px; }
}

.my-large {
    margin-top: 60px;
    margin-bottom: 60px;

    @include sm { margin-top: 100px; margin-bottom: 100px; }
    @include md { margin-top: 160px; margin-bottom: 160px; }
    @include lg { margin-top: 200px; margin-bottom: 200px; }
    @include xl { margin-top: 240px; margin-bottom: 240px; }
    @include xxl { margin-top: 280px; margin-bottom: 280px; }
}
