.hbspt-form {
    ::placeholder {
        text-transform: uppercase;
    }

    input[type="text"],
    input[type="email"], 
    textarea,
    select {
        @extend .form-control;
        font-family: $font-family-sans-alt;
        font-weight: $font-weight-bold;
        letter-spacing: 0.44px;
    }

    input[type="submit"] {
        @extend .btn;
        @extend .btn-light;
    }

    textarea {
        min-height: 150px;
    }

    ul.hs-error-msgs {
        margin: 0.5rem 0 1rem;
        padding: 0;
        list-style-type: none;
        font-size: rem(12px);
    }
}

.hbspt-form.content-form {
    .hs-form-field {
        width: 100%;
        margin: 0 0 20px 0;
    }
    .custom-select-container {
        border: 1px solid $gray-400;
    }
    .custom-select-opener:after {
        border-top-color: $gray-400;
    }
    .custom-select-panel {
        background-color: $gray-400;
    }
}
