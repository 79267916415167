html, body {
    scroll-behavior: smooth;
}

.ad-jump-links-wrap {
    background: #000;
    color: #FFF;
    margin: 0 0 50px 0;
    .col-md-12 {
        display: flex;
    }
    .ad-jump-links {
        display: flex;
        column-gap: 30px;
        padding: 0 30px;
        margin: 0;
        flex-wrap: wrap;
        li {
            list-style: none;
        }
    }    
}

.ad-campaign {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
    @include md {
        margin-bottom: 50px;
        padding-bottom: 50px;    
    }
    &:last-child {
        border-bottom: none;
    }
    .advertisement {
        max-width: 100%;
        margin: 0 20px 20px 0;
        &__iframe {
            display: block;
            max-width: 100%;
        }
    }
    .ad-campaign-title {
        margin-bottom: 20px;
    }
    .ad-campaign-intro {
        margin: 0 0 30px 0;
    }
    button {
        margin-top: 10px;
        appearance: none;
        border: 1px solid #000;
        background-color: #FFF;
        text-decoration: none;
    }
}

.more-ads {
    margin: 50px 0;
    &:first-child {
        margin-top: 30px;
    }
    .col-md-12 {
        box-sizing: border-box;
        .see-more-block {
            padding: 20px;    
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    margin: 0 0 10px 0;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }        
        }
    }
}