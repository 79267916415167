// Colors
$white:                     #FFFFFF;
$lightest-blue:             #E4EAED;
$lightest-tan:              #EDEDEA;
$dark-grey:                 #393939;
$darker-grey:               #1E1E1E;
$black:                     #000000;

$yellow:                    #E1B966;
$red:                       #D16658;
$blue:                      #95A6B9;
$dark-blue:                 #7E90A2;

$primary:                   $red;
$secondary:                 $yellow;
$light:                     $lightest-blue;
$dark:                      $darker-grey;

$body-color:                $darker-grey;

// Fonts
$font-family-serif:         clarendon-text-pro, serif;
$font-family-sans:          benton-sans, sans-serif;
$font-family-sans-alt:      benton-sans-condensed, sans-serif;;
$font-family-base:          $font-family-sans;

$font-weight-light:         300;
$font-weight-regular:       500;
$font-weight-bold:          700;

$font-size-base:            1rem;
$line-height-base:          1.375;

$headings-color:            $body-color;

$paragraph-margin-bottom:   0.75rem;

$input-padding-x:           0.5rem;
$input-padding-y:           0.5rem;
$input-border-radius:       0;
$input-font-size:           0.875rem;

$btn-font-family:           $font-family-sans-alt;
$btn-font-weight:           $font-weight-bold;
$btn-border-width:          2px;
$btn-border-radius:         0;
$btn-padding-y:             0.875rem;
$btn-padding-x:             1.5rem;
$btn-padding-y-sm:          0.375rem;
$btn-padding-x-sm:          1rem;

// Transitions
$snappy:                    cubic-bezier(0.42, 0, 0.05, 0.99);
$ease-in:                   cubic-bezier(0.73, 0.08, 1, 1);
$ease-out:                  cubic-bezier(0.38, 0.99, 1, 1);
$ease-out-back:             cubic-bezier(0.29, 0.97, 0, 1.85);
$ease-out-back-easy:        cubic-bezier(0, 1.35, 0.77, 0.99);

$btn-transition:            color 0.25s, background-color 0.25s, border-color 0.25s, box-shadow 0.25s;

$spacer: 30px;
$spacers: (
    sm: $spacer,
    md: ($spacer * 1.5),
    lg: ($spacer * 2)
);

$grid-gutter-width:         $spacer;

$grid-breakpoints: (
    xxs: 0,
    xs: 375px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);
