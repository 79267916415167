@mixin xs {
    @media (min-width: map-get($grid-breakpoints, 'xs')) { @content; }
}

@mixin sm {
    @media (min-width: map-get($grid-breakpoints, 'sm')) { @content; }
}

@mixin md {
    @media (min-width: map-get($grid-breakpoints, 'md')) { @content; }
}

@mixin lg {
    @media (min-width: map-get($grid-breakpoints, 'lg')) { @content; }
}

@mixin xl {
    @media (min-width: map-get($grid-breakpoints, 'xl')) { @content; }
}

@mixin xxl {
    @media (min-width: 1440px) { @content; }
}

@mixin fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.fill { @include fill; }

@mixin cover {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.cover { @include cover; }

@mixin contain {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.cover { @include cover; }

@mixin section-margin-top {
    margin-top: $spacer;

    @include md {
        margin-top: $spacer*2;
    }

    @include lg {
        margin-top: $spacer*3;
    }

    @include xl {
        margin-top: $spacer*4;
    }
}

@mixin section-margin-bottom {
    margin-bottom: $spacer;

    @include md {
        margin-bottom: $spacer*2;
    }

    @include lg {
        margin-bottom: $spacer*3;
    }

    @include xl {
        margin-bottom: $spacer*4;
    }
}

@mixin section-margin-top-small {
    margin-top: $spacer;

    @include md {
        margin-top: $spacer*2;
    }
}

@mixin section-margin-bottom-small {
    margin-bottom: $spacer;

    @include md {
        margin-bottom: $spacer*2;
    }
}

@mixin section-pull-up {
    margin-top: -$spacer;

    @include md {
        margin-top: -$spacer*2;
    }

    @include lg {
        margin-top: -$spacer*3;
    }

    @include xl {
        margin-top: -$spacer*4;
    }
}

@mixin section-pull-up-small {
    margin-top: -$spacer;

    @include md {
        margin-top: -$spacer*2;
    }
}

@mixin section-padding {
    padding: $spacer 0;

    @include md {
        padding: $spacer*2 0;
    }

    @include lg {
        padding: $spacer*3 0;
    }

    @include xl {
        padding: $spacer*4 0;
    }
}

@mixin off-grid-width {
    width: calc(100% + #{$grid-gutter-width / 2});

    @include xs {
        width: calc(100% + 50px);
    }

    @include sm {
        width: calc(50vw + #{map-get($container-max-widths, 'sm') / 2} - #{$grid-gutter-width / 2});
        margin-left: 0;
    }

    @include md {
        width: calc(50vw + #{map-get($container-max-widths, 'md') / 2} - #{$grid-gutter-width / 2});
    }

    @include lg {
        width: calc(50vw + #{map-get($container-max-widths, 'lg') / 2} - #{$grid-gutter-width / 2});
    }

    @include xl {
        width: calc(50vw + #{map-get($container-max-widths, 'xl') / 2} - #{$grid-gutter-width / 2});
    }
}

@mixin extend-to-edge {
    width: calc(100% + #{$grid-gutter-width / 2});

    @include sm {
        width: calc(100% + (100vw - #{map-get($container-max-widths, 'sm')}) / 2 + #{$grid-gutter-width / 2});
    }

    @include md {
        width: calc(100% + (100vw - #{map-get($container-max-widths, 'md')}) / 2 + #{$grid-gutter-width / 2});
    }

    @include lg {
        width: calc(100% + (100vw - #{map-get($container-max-widths, 'lg')}) / 2 + #{$grid-gutter-width / 2});
    }

    @include xl {
        width: calc(100% + (100vw - #{map-get($container-max-widths, 'xl')}) / 2 + #{$grid-gutter-width / 2});
    }
}

@mixin extend-to-edges {
    width: 100vw;
    margin-left: #{$grid-gutter-width / -2};

    @include xs {
        margin-left: -50px;
    }

    @include sm {
        margin-left: calc((#{map-get($container-max-widths, 'sm')} - 100vw) / 2 - #{$grid-gutter-width / 2});
    }

    @include md {
        margin-left: calc((#{map-get($container-max-widths, 'md')} - 100vw) / 2 - #{$grid-gutter-width / 2});
    }

    @include lg {
        margin-left: calc((#{map-get($container-max-widths, 'lg')} - 100vw) / 2 - #{$grid-gutter-width / 2});
    }

    @include xl {
        margin-left: calc((#{map-get($container-max-widths, 'xl')} - 100vw) / 2 - #{$grid-gutter-width / 2});
    }
}
.extend-to-edges { @include extend-to-edges; }

@mixin pull-to-edge {
    float: right;
    margin-right: calc(100% + #{$grid-gutter-width / 2});

    @include sm {
        margin-right: calc(#{map-get($container-max-widths, 'sm') / 2} - 50vw - #{$grid-gutter-width / 2});
    }

    @include md {
        margin-right: calc(#{map-get($container-max-widths, 'md') / 2} - 50vw - #{$grid-gutter-width / 2});
    }

    @include lg {
        margin-right: calc(#{map-get($container-max-widths, 'lg') / 2} - 50vw - #{$grid-gutter-width / 2});
    }

    @include xl {
        margin-right: calc(#{map-get($container-max-widths, 'xl') / 2} - 50vw - #{$grid-gutter-width / 2});
    }
}

@mixin debug {
    outline: 1px solid red;
}
.debug { @include debug; }

@function rem( $size ) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

