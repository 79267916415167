// Default margins for pageContent blocks

body.small-margins nav.section-margins,
body.small-margins section.section-margins {
    @include section-margin-bottom-small;
}

body:not(.small-margins) nav.section-margins,
body:not(.small-margins) section.section-margins {
    @include section-margin-bottom;
}

// Default margins for section following hero

section.hero.hero-work + section {
    @include section-margin-top-small;
}

section.hero:not(.hero-work) + section:not(.call-to-action) {
    @include section-margin-top;
}

// Offset default margins for .call-to-action

// body.small-margins section.hero + section.call-to-action,
body.small-margins section.off-grid-bubble + section.call-to-action,
body.small-margins section.off-grid-content + section.call-to-action {
    @include section-pull-up-small;
}

// body:not(.small-margins) section.hero + section.call-to-action,
body:not(.small-margins) section.off-grid-bubble + section.call-to-action,
body:not(.small-margins) section.off-grid-content + section.call-to-action {
    @include section-pull-up;
}

// Default margins for last section

section.section-margins:last-child {
    @include section-margin-bottom;
}

section.default-content {
    .sidebar {
        ul, ol, dl {
            @extend .vertical-rule;
            list-style-type: none;
            padding-left: 1.5rem;
        }
    }
}

section.article-grid {
    img,
    video {
        display: block;
        width: 100%;
    }

    .excerpt.full-width .img-wrap {
        @include sm {
            @include extend-to-edge;
            @include clearfix;
            float: right;    
        }
    }
}

nav.subnav + section.article-grid {
    margin-top: $spacer;

    @include lg {
        margin-top: $spacer*2;
    }

    @include xl {
        margin-top: $spacer*3;
    }
}

section.image-grid {
    img,
    video {
        display: block;
        width: 100%;
    }

    .full-width img,
    .full-width video {
        @include extend-to-edge;
        @include clearfix;
        float: right;
        max-width: none;
    }
}

section.callout {
    @include clearfix;

    .content-wrap {
        position: relative;
        padding: 30px 30px 30px 0;

        .bg {
            position: absolute;
            right: 0;
            top: 0;
            @include off-grid-width;
            height: 100%;
            background-color: $lightest-tan;
        }
    }

    .container {
        position: relative;
    }

    @include md {
        .content-wrap {
            padding: 50px 50px 50px 0;
        }
    }
}

section.off-grid-image {
    .content-wrap {
        position: relative;
        @include off-grid-width;
        padding: 30px;

        blockquote,
        .blockquote {
            max-width: 320px;
            border-left-color: $white;
        }

        .bg {
            @include fill;
            background-color: $lightest-tan;
        }
    }

    .container {
        position: relative;
    }

    @include xs {
        .content-wrap {
            padding-right: 50px;
        }
    }

    @include md {
        .content-wrap {
            padding: 50px;
        }
    }
}

section.off-grid-bubble {
    .content-wrap {
        position: relative;
        @include off-grid-width;
        padding: 30px;
        overflow: hidden;

        blockquote,
        .blockquote {
            max-width: 360px;
            border-left-color: $white;
        }

        .bg {
            @include fill;
            background-color: $lightest-tan;
        }
    }

    .container {
        position: relative;
    }

    .img-wrap {
        overflow: visible;
        width: 135%;
        margin-bottom: -35%;

        img {
            border-radius: 50%;
            overflow: hidden;
        }
    }

    @include xs {
        .content-wrap {
            padding-right: 50px;
        }
    }

    @include md {
        .content-wrap {
            padding: 50px;
        }

        .img-wrap {
            float: right;
            margin-right: -35%;
        }
    }

    @include lg {
        .img-wrap {
            width: 100%;
            margin-right: -25%;
        }
    }
}

section.related-content {
    @include section-margin-top;

    .content-wrap {
        position: relative;
        @include off-grid-width;
        padding: 30px;

        .bg {
            @include fill;
            background-color: $lightest-tan;
        }
    }

    .container {
        position: relative;
    }

    .blockquote {
        border-left-color: $white;
    }

    @include md {
        .content-wrap {
            padding: 50px;
        }
    }
}

section.off-grid-content {
    @include clearfix;

    .content-wrap {
        position: relative;
        padding: 30px 0;

        .bg {
            position: absolute;
            right: 0;
            top: 0;
            @include off-grid-width;
            height: 100%;
            background-color: $lightest-tan;
        }
    }

    .container {
        position: relative;
    }

    .blockquote {
        border-left-color: $white;
    }

    @include md {
        .content-wrap {
            padding: 50px 0;
        }
    }
}

section.logo-ticker {
    @include clearfix;

    .content-wrap {
        position: relative;
        @include off-grid-width;
        float: right;
        padding: 30px 0 30px 30px;

        .bg {
            @include fill;
            background-color: $lightest-tan;
        }
    }

    .container {
        position: relative;
    }

    .logo-row {
        display: none;

        &.active { display: flex; }
    }

    .blockquote {
        border-left-color: $white;
    }

    @include md {
        .content-wrap {
            padding: 60px 0 60px 60px;
        }
    }
}


section.call-to-action {
    position: relative;
    padding: 80px 0;
    background-color: $darker-grey;
    color: $white;
    
    &.lightBackgroundWithDarkText {
        background-color: $white;
        color: $darker-grey;

        .hbspt-form {
            input[type="text"],
            input[type="email"], 
            select {
                background: transparent;
                border: none;
                border-bottom: 1px solid $darker-grey;
                color: $darker-grey;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    video {
        @include fill;
        z-index: 1;
        display: block;
        object-fit: cover;
    }

    .img-wrap {
        @include fill;
        z-index: 1;
        padding-top: 0 !important;

        img {
            @include fill;
            display: block;
            object-fit: cover;
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .hbspt-form {
        .hs-form-field {
            margin-bottom: 30px;

            &.hs-fieldtype-select {
                .input {
                    &:after {
                        color: #FFF;
                    }    
                }
            }
        }


        input[type="text"],
        input[type="email"],
        select {
            background: transparent;
            border: none;
            border-bottom: 1px solid $white;
            color: $white;
        }

        input[type="submit"] {
            @extend .btn-block;
        }

        ::placeholder {
            color: $white;
        }
    }

    h1, h2, h3 {
        @include cta;
        margin-bottom: 30px;
    }

    @include md {
        display: flex;
        align-items: center;
        min-height: 450px;
        padding: 90px 0;
    }
}

section.slider,
section.video {
    .slide,
    .video {
        position: relative;
        display: block;
        width: 100%;
        background-color: $darker-grey;
    }

    .video::before {
        content: " ";
        position: absolute;
        z-index: 1;
        left: calc(50% - 30px);
        top: calc(50% - 30px);
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: rgba(0,0,0,0.25) url('../icons/play.svg') 50% 50%;
        background-size: contain;
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
        opacity: 1;
        transition: opacity 0.5s;
    }

    .video-active {
        &::before,
        img {
            opacity: 0;
        }
    }

    img {
        display: block;
        width: 100%;
        opacity: 1;
        transition: opacity 0.5s;
    }

    iframe {
        @include fill;
        display: block;
    }

    .flickity-page-dots {
        position: relative;
        left: 0;
        bottom: 0;
        margin: 20px 0;
        text-align: left;

        .dot {
            position: relative;
            margin: 0 10px;
            width: 14px;
            height: 14px;
            opacity: 1;
            background: transparent;
            border: 1px solid $black;

            &:first-child {
                margin-left: 0;
            }

            &::before {
                content: " ";
                position: absolute;
                left: 2px;
                top: 2px;
                width: 8px;
                height: 8px;
                background-color: $black;
                border-radius: 50%;
                opacity: 0;
                transition: opacity 0.25s;
            }

            &.is-selected::before {
                opacity: 1;
            }
        }
    }

    .flickity-button {
        display: none;
    }

    @include md {
        .flickity-page-dots {
            // display: none;
        }

        .flickity-button {
            display: block;
            background: transparent;
        }

        .flickity-prev-next-button.previous {
            left: -60px;
        }

        .flickity-prev-next-button.next {
            right: -60px;
        }
    }
}
